@import 'src/scss/vars/colors.scss';
@import 'src/scss/vars/layout.scss';

$name: 'AppHeader';

$menu-width: 15rem;

.#{$name} {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
  box-sizing: border-box;
  height: $header-height;
  margin: auto;
  padding: 0 var(--app-vertical-margins);
  color: $white;

  &__menu {
    position: absolute;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;

    &--open {
      .#{$name} {
        &__menu {
          &-button {
            & > * {
              background-color: $purple;
            }
          }

          &-backdrop {
            display: block;
          }

          &-navigation {
            left: 0;
          }
        }
      }
    }

    &-button {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 0.5rem;
      box-sizing: border-box;
      padding: 0.375rem;
      height: 2.75rem;
      width: 2.75rem;
      background: transparent;
      outline: none;
      border: none;
      border-radius: 0.25rem;
      user-select: none;

      &:active {
        & > * {
          background-color: $purple;
        }
      }

      & > * {
        display: block;
        width: 1.5rem;
        height: 0.125rem;
        background-color: $white_a_90;
        border-radius: 0.0625rem;
        transition: 200ms ease-out;

        &:nth-child(2) {
          width: 2rem;
        }
      }
    }

    &-backdrop {
      display: none;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      background-color: $white_a_25;
    }

    &-navigation {
      position: fixed;
      top: 0;
      bottom: 0;
      left: -$menu-width;
      z-index: 2;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      box-sizing: border-box;
      padding: 2.5rem 0;
      width: $menu-width;
      background-color: $dark_gray;
      transition: 200ms ease-out;
    }

    &-close {
      position: absolute;
      top: 1.25rem;
      right: 1.25rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin: 0;
      height: 1rem;
      width: 1rem;
      background: transparent;
      outline: none;
      border: none;

      svg {
        width: 0.75rem;
        fill: $white_a_90;
      }
    }
  }

  &__logo {
    flex: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    margin: auto;
    height: 100%;
    font-weight: 300;
    font-size: 1.5rem;
    letter-spacing: 0.1625rem;
    user-select: none;

    svg {
      height: 2.25rem;
    }
  }

  &__nav-link {
    display: block;
    box-sizing: border-box;
    padding: 1rem var(--app-vertical-margins);
    width: 100%;
    font-size: 1.5rem;
    font-weight: 300;
    letter-spacing: 0.03125rem;
    color: $white_a_90;
    border-bottom: 0.0625rem solid $dark_gray_l_10;
    text-decoration: none;

    &:visited {
      color: unset;
    }
  }
}
