@import 'src/scss/vars/colors.scss';
@import 'src/scss/vars/layout.scss';

// Use normalize.css as a reset sheet
@import-normalize;

html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background-color: $black;
  overscroll-behavior: none;
  font-family: 'Red Hat Text', sans-serif;
  -webkit-tap-highlight-color: transparent;
}

#root {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

a,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  color: inherit;
}

button,
input,
textarea {
  font-family: 'Red Hat Text', sans-serif;
}

.monospace {
  font-family: 'Roboto Mono', monospace;
}

:root {
  @media (max-width: $small-screen-end) {
    --app-vertical-margins: 1rem;
  }

  @media (min-width: $medium-screen-start) and (max-width: $medium-screen-end) {
    --app-vertical-margins: 1.5rem;
  }

  @media (min-width: $large_screen_start) {
    --app-vertical-margins: 2rem;
  }
}
