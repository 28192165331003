@import 'src/scss/vars/colors.scss';
@import 'src/scss/vars/layout.scss';

$name: 'App';

.#{$name} {
  height: 100%;
  width: 100%;
  margin: auto;
  overflow-x: hidden;

  &__content {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    width: 100%;
  }

  &__header {
    width: 100%;
  }

  &__body {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__footer {
    width: 100%;
  }
}
