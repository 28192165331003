@import 'src/scss/vars/colors.scss';
@import 'src/scss/vars/layout.scss';

$name: 'AppFooter';

.#{$name} {
  box-sizing: border-box;
  width: 100%;
  color: $white;
  background-image: linear-gradient(to bottom right, $purple -200%, $black 100%);
  user-select: none;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
  }

  &__links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__link-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__link {
    font-weight: 300;
    letter-spacing: 0.0625rem;
    color: $white_a_90;
    white-space: nowrap;
    text-decoration: none;

    &:visited {
      color: unset;
    }

    &--main {
      font-weight: 400;
    }
  }

  &__contacts {
    box-sizing: border-box;
    width: 100%;
  }

  &__contact {
    &-title {
      margin-bottom: 0.5rem;
      font-size: 1.25rem;
      font-weight: 400;
      letter-spacing: 0.0625rem;
      color: $white;
    }

    &-subtitle {
      margin-bottom: 2.5rem;
      font-size: 1.125rem;
      font-weight: 300;
      letter-spacing: 0.03125rem;
      color: $white_a_90;
    }

    &-options {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-option {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color: $purple;
      border-radius: 0.25rem;
      transition: 200ms ease-in;
    }
  }

  &__rights {
    &-bar {
      margin-bottom: 1rem;
      height: 0.0625rem;
      background-color: $white_a_75;
    }

    &-message {
      font-weight: 300;
      text-align: center;
    }
  }
}

@media (max-width: $small-screen-end) {
  $horizontal-padding: 0.25rem;

  .#{$name} {
    padding: 2rem var(--app-vertical-margins) 4rem var(--app-vertical-margins);

    &__container {
      gap: 3rem;
      min-width: 20rem;
      max-width: 22rem;
    }

    &__link-column {
      gap: 0;
    }

    &__link {
      padding: 0.375rem $horizontal-padding;
      font-size: 0.75rem;

      &--main {
        font-size: 0.875rem;
        margin-bottom: 0.25rem;
      }
    }

    &__contacts {
      padding: 0 $horizontal-padding;
    }

    &__contact {
      &-option {
        padding: 0.25rem;
        height: 2.75rem;
        width: 2.75rem;

        svg {
          height: 2rem;
        }
      }
    }

    &__rights {
      &-bar {
        width: 18rem;
      }

      &-message {
        font-size: 0.875rem;
      }
    }

    &__logo {
      svg {
        height: 5rem;
      }
    }
  }
}

@media (min-width: $medium-screen-start) {
  $horizontal-padding: 0.25rem;

  .#{$name} {
    padding: 2rem var(--app-vertical-margins) 5rem var(--app-vertical-margins);

    &__container {
      gap: 4rem;
      width: 28rem;
    }

    &__link-column {
      gap: 0;
    }

    &__link {
      padding: 0.375rem $horizontal-padding;
      font-size: 0.875rem;

      &--main {
        font-size: 1rem;
        margin-bottom: 0.25rem;
      }
    }

    &__contacts {
      padding: 0 $horizontal-padding;
    }

    &__contact {
      &-option {
        padding: 0.25rem;
        height: 4rem;
        width: 4rem;

        svg {
          height: 2.5rem;
        }
      }
    }

    &__rights {
      &-bar {
        width: 23rem;
      }

      &-message {
        font-size: 1rem;
      }
    }

    &__logo {
      svg {
        height: 6rem;
      }
    }
  }
}
